const showMoreButtons = document.querySelectorAll('.js-show-hidden-text');

if (showMoreButtons && showMoreButtons.length > 0) {
    const handleShowMore = e => {
        const { target } = e;
        const parent = target.parentElement;

        const hiddenText = parent.querySelector('.js-hidden-text');

        const showText = target.getAttribute('data-show-text');
        const hideText = target.getAttribute('data-hide-text');

        if (!target.classList.contains('active')) {
            target.classList.add('active');
            target.textContent = hideText;
            hiddenText.classList.add('show');
        } else {
            target.classList.remove('active');

            hiddenText.classList.remove('show');

            window.scrollTo({
                top: parent.getBoundingClientRect().top + window.scrollY - 150,
                behavior: 'smooth',
            });

            setTimeout(() => {
                target.textContent = showText;
            }, 200);
        }
    };

    showMoreButtons.forEach(button => {
        button.addEventListener('click', handleShowMore);
    });
}
